import React from 'react';
import BodyText from 'stoomlink-commons-ui/BodyText/BodyText';

import {
  CardContainer,
  Content,
} from './styledComponent';

function JobCard(props) {
  const { role, locations, link } = props;
  return (
    <a href={link} style={{ textDecoration: 'none' }}>
      <CardContainer>
        <Content>
          <BodyText>Role</BodyText>
          <BodyText type="eyebrow">{role}</BodyText>
        </Content>
        <Content>
          <BodyText>
            {`${locations.length > 1 ? 'Locations' : 'Location'}`}
          </BodyText>
          {locations.map((el, i) => {
            return (
              <BodyText key={`${el + i}`} type="eyebrow">
                {el}
              </BodyText>
            );
          })}
        </Content>
      </CardContainer>
    </a>
  );
}

export default JobCard;
