import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import Title from 'stoomlink-commons-ui/Title/Title';
import BodyText from 'stoomlink-commons-ui/BodyText/BodyText';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';
import Container from '../layouts/Container';
import TeamMember from '../components/TeamMember';
import Footer from '../components/Footer';
import Card from '../components/Card';
import team from '../../content/team';
import JobCard from '../components/JobCard';
import Button from '../components/Button';
import shuffleArray from '../utils/shuffleArray';
import HeroImg from '../assets/images/about-team-hero.jpg';

const TitleContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;

  @media screen and (min-width: 780px){
    padding-top: 160px;
    padding-bottom: 80px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 780px){
    display: flex;
    flex-wrap: nowrap;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  order: 2;

  @media screen and (min-width: 780px) {
    order: unset;
    width: calc(100% / 20 * 9);
  }
`;
const RightSide = styled.div`
  order: 1;
  width: 100%;

  @media screen and (min-width: 780px) {
    order: unset;
    margin-left: calc(100% / 20 * 2);
    width: calc(100% / 20 * 9);
  }
`;
const Hero = styled.div`
  margin-top: 32px;
  width: 100%;
  padding-bottom: 56.25%;
  background-image: url('${HeroImg}');
  background-position: center 85%;
  background-size: cover;

  @media screen and (min-width: 780px) {
    margin-top: 80px;
    padding-bottom: 149.8%;
    background-position: center;
  }
`;

const Content = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: 780px){
    margin-bottom: 48px;
  }

  p {
    margin-top: 16px;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 32px;

  @media screen and (max-width: 667px){
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  margin-top: 130px;

  h1 {
    margin-bottom: 24px;
    color: white;
  }

  @media screen and (max-width: 780px){
    margin-top: 64px;
    margin-bottom: 58px;
  }
`;

const DNAContainer = styled.div`
  margin: 0 -7.5vw;
  margin-bottom: 32px;
  padding: 32px 32px;
  background-color: #000000;
  color: #FFFFFF;

  @media screen and (min-width: 780px) {
    margin: 0;
    margin-bottom: 48px;
    padding: 64px 64px;
  }
`;
const DNATitle = styled.h2`
  margin: 0;
  max-width: 640px;
  font-family: "HN Display";
  font-size: 32px;
  line-height: 44px;
  line-height: 36px;

  @media screen and (min-width: 780px) {
    margin-right: calc(100% / 20 * 12);
    font-size: 40px;
    line-height: 44px;
  }
`;
const DNAButton = styled(Button)`
  margin-top: 32px;
`;

const TeamPage = () => {
  const {
    pageTitle,
    title,
    // summary,
    members,
    dna,
    jobOpenings,
  } = team;

  const [shuffledTeam, setSuffledTeam] = useState([]);

  useEffect(() => {
    setSuffledTeam(shuffleArray(members));
  }, [members]);

  return (
    <ThemeProvider themeStyle="light">
      <Layout>
        <Head title={pageTitle} />
        <Container>
          <ContentContainer>
            <LeftSide>
              <TitleContainer>
                <Title
                  displayLevel={5}
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              </TitleContainer>
              {dna.map((el, i) => {
                return (
                  <Content key={`${el + i}`}>
                    <Title level={3}>{el.title}</Title>
                    {el.paragraphs.map((p, j) => {
                      return (
                        <BodyText key={`${p + j}`}>{p}</BodyText>
                      );
                    })}
                  </Content>
                );
              })}
            </LeftSide>
            <RightSide>
              <Hero />
            </RightSide>
          </ContentContainer>
          <DNAContainer>
            <DNATitle>
              Want to learn more about the way we work?
            </DNATitle>
            <DNAButton href="http://open.stoomlink.io" target="_blank" kind="secondary">
              Explore our DNA
            </DNAButton>
          </DNAContainer>
          <Title displayLevel={6}>
            Look at these happy faces.
          </Title>
          <TeamContainer>
            {shuffledTeam.map((el, i) => {
              return (
                <TeamMember
                  key={`${el + i}`}
                  member={el}
                />
              );
            })}
          </TeamContainer>
          {(jobOpenings && jobOpenings.openings.length > 0) && (
            <CardContainer>
              <Card theme="secondary">
                <Title displayLevel={4}>{jobOpenings.title}</Title>
                <BodyText>{jobOpenings.content}</BodyText>
                {jobOpenings.openings.map((job, i) => {
                  return (
                    <JobCard
                      key={`${job.role + i}`}
                      locations={job.locations}
                      role={job.role}
                      link={job.link}
                    />
                  );
                })}
              </Card>
            </CardContainer>
          )}
        </Container>
        <Footer
          className="noBefore"
          teamLink={false}
        />
      </Layout>
    </ThemeProvider>
  );
};

export default TeamPage;
