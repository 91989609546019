import PropTypes from 'prop-types';
import React from 'react';

import Container from './styledComponent';

const Card = ({ children, theme, style }) => (
  <Container style={style} theme={theme}>
    {children}
  </Container>
);

Card.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
};

Card.defaultProps = {
  theme: 'primary',
};

export default Card;
