import styled from 'styled-components';
import { colors } from '../../theme/index';

const styleForTheme = (theme) => {
  switch (theme) {
    case 'primary':
      return `{
        border: 5px solid ${colors.accent};
        background: ${colors.white};
        p, h1, h2, h3, h4, h5, h6 {
          color: ${colors.black};
        }
      }`;
    case 'accent':
      return `{
        background: ${colors.accent};
        h1, h2, h3, h4, h5, h6 {
          color: ${colors.white};
          span {
            color: ${colors.white};
          }
        }
        p {
          color: ${colors.black};
        }
      }`;
    case 'secondary':
      return `{
        background: ${colors.black};
        p, h1, h2, h3, h4, h5, h6 {
          color: ${colors.white};
        }
      }`;
    default:
      return `{
        background: ${colors.white};
      }`;
  }
};

const Container = styled.div`

  ${(props) => styleForTheme(props.theme)}
  p{
    width: 60%;
    margin-bottom: 24px;
    @media screen and (max-width: 667px){
      width: 100%;
    }
  }
  h1 {
    margin-bottom: 24px;
  }
  padding: 50px 102px;
  margin: 8px 0;
  @media screen and (max-width: 780px){
    padding: 24px 32px;
  }
`;

export default Container;
