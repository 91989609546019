import styled from 'styled-components';
import { colors } from '../../theme';

export const CardContainer = styled.div`
    display: flex;
    padding: 32px 60px;
    background: #FFFFFF;
    margin: 12px 0;
    justify-content: space-between;
    @media screen and (max-width: 667px){
        flex-direction: column;
        padding: 24px 32px;
    }
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.10), 0 6px 13px 0 rgba(0,0,0,0.07), 0 3px 7px 0 rgba(0,0,0,0.06), 0 2px 4px 0 rgba(0,0,0,0.05), 0 1px 2px 0 rgba(0,0,0,0.04), 0 0 0 0 rgba(0,0,0,0.03);
    p {
        color: #000000;
    }
`;

export const Content = styled.div`
    flex-basis: 50%;
    color: ${colors.black};

    &:last-child{
        flex-basis: 35%;
    }
    p{
      margin-bottom: 0;
    }
    p:first-of-type{
      margin-bottom: 16px;
    }

    @media screen and (max-width: 667px){
        flex-basis: 100%;
        p:first-child {
            margin-bottom: 24px;
        }
    }
`;
